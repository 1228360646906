import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Video = styled.video`
  margin: 32px;
  width: 100%;
  max-width: 800px;
`;

export const Shinobi = () => {
  return (
    <Container>
      <Video controls>
        <source src="https://kodoninja.sfo2.digitaloceanspaces.com/ninjademo264.mp4" />
        Your browser does not support the video tag.
      </Video>
    </Container>
  );
};
