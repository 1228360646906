import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './bot.module.scss';
import { bots } from '../../data/';
import ReactMarkdown from 'react-markdown';

const Bot = () => {

  const { id } = useParams();
  const bot = bots.find( b => b.id === id);
  console.log(bot.info);

  return <div className={styles.container}>
    <img className={styles.picture} src={bot.pic} alt={bot.name} />
    <ReactMarkdown className={styles.description} source={bot.info} />
  </div>
}

export default Bot;
