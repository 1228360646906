import React from 'react';
import { aboutMe1, aboutMe2, aboutMe3, particularSkills } from '../../data/';
import {
  container,
  about,
  title,
  copy,
  skillset,
  skillColumns,
  column,
  skillTitle,
  skillList,
} from './home.module.scss';

const skillColumn = ({ name, skills}) => (
  <div className={column} key={name}>
    <div className={skillTitle}>{name}</div>
    <ul className={skillList}>
      {skills.map(s => <li key={s}>{s}</li> )}
    </ul>
  </div>
)

const Home = () => <div className={container}>
  <div className={about}>
    <div className={title}>About</div>
    <div className={copy}>{aboutMe1}</div>
    <div className={copy}>{aboutMe2}</div>
    <br />
    <div className={title}>TL;DR</div>
    <div className={copy}>{aboutMe3}</div>
  </div>
  <div className={skillset}>
    <div className={title}>Skills</div>
    <div className={skillColumns}>
      {particularSkills.map(skillColumn)}
    </div>
  </div>
</div>;

export default Home
