import React from 'react';
import { Link } from 'react-router-dom';
import styles from './bots.module.scss';
import { bots } from '../../data/';

const renderBot = ({ pic, id, name}) => <div className={styles.bot} key={id}>
  <Link to={`/bots/${id}`}>
    <div className={styles[id]} />
    <div className={styles.name}>{name}</div>
  </Link>
</div>;

const Bots = () => <div className={styles.container}>
  {bots.map(renderBot)}
</div>

export default Bots;
