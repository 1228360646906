import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Navbar from './components/navbar';
import Home from './components/home';
import Bots from './components/bots';
import Bot from './components/bot';
import { Shinobi } from './components/Shinobi';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/bots/:id" component={Bot} />
        <Route exact path="/bots" component={Bots} />
        <Route exact path="/bannerlord" component={Shinobi} />
        <Route exact path="/*" component={() => Redirect({ to: '/' })} />
        {/* <XmlTool></XmlTool> */}
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
